<template>
  <div class="wrapper">
    <UnAuthHeader />
    <main class="page">
      <div class="container-615">
        <section class="entity">
          <h1 class="entity__title title">Восстановление пароля</h1>
          <form class="entity__form form">
            <article class="entity__block">
              <Input
                @value-input="saveValue"
                title="Введите свой email"
                field="email"
                type="email"
                placeholder="test@imigrata.com"
                custom_class="form__email"
                :error="errors.email"
              />
            </article>
            <article>
              <input
                type="submit"
                placeholder=""
                class="form__submit"
                value="Восстановить пароль"
              />
            </article>
          </form>
          <div class="entity__already-login">
            <a href="/auth" class="entity__already-login-link"
              >Вернуться к авторизации</a
            >
          </div>
        </section>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import { API } from "../../helpers/api";
import { Validation } from "../../helpers/validation";

import UnAuthHeader from "../../components/headers/UnAuth.vue";
import Input from "../../components/utils/Input.vue";
import Footer from "../../components/Footer.vue";

export default {
  name: "RecoveryInit",
  components: {
    UnAuthHeader,
    Input,
    Footer,
  },
  data() {
    return {
      values: {
        email: "",
      },
      errors: {
        email: "",
      },
    };
  },
  methods: {
    saveValue(data) {
      this.values[data.field] = data.value;
      for (const key in this.errors) {
        this.errors[key] = "";
      }
    },
    async sendForm() {
      this.errors.email = Validation.checkEmail(this.values.email);

      if (Object.values(this.errors).some((result) => result.length > 0))
        return;

      try {
        const response = await API.send(
          "generateRecoveryToken",
          this.values,
          "POST"
        );

        if (response.status !== 0) {
          this.errors.email = response.user_message;
          return;
        }
      } catch (e) {
        this.errors.email = "Неизвестная ошибка. Попробуйте позже.";
      }
    },
  },
};
</script>
